import styled, { css } from 'styled-components'

export const WrapContent = styled.div`
  box-sizing: border-box;

  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-items: center;
  }

  @media screen and (max-width: 430px) {
  }
`

export const MiddleTextContent = styled.div`
  max-width: 810px;
  width: 810px;

  margin-top: 100px;
  grid-area: 1 / 3 / 2 / 6;

  scroll-margin-top: 91px;
  /* display: flex;
  
    align-items: center;
    justify-content: center; */
  @media only screen and (max-width: 1000px) {
    grid-area: 2 / 1 / 3 / 2;
    width: 100%;
    margin-top: 20px;
  }

  @media screen and (max-width: 830px) {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }
`
export const LeftColumn = styled.div`
  position: relative;
  margin-top: 100px;
  height: auto;
  grid-area: 1 / 1 / 2 / 3;
  padding-top: 20px;
  padding-left: 70px;
  padding-right: 50px;
  min-width: 340px;
  max-width: 350px;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  @media only screen and (max-width: 1000px) {
    max-width: unset;
    grid-area: 1 / 1 / 2 / 2;
    margin-top: 40px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 30px;
  }

  @media screen and (max-width: 430px) {
  }
`
export const WrapLeftContent = styled.div`
  position: sticky;
  scroll-behavior: smooth !important;

  top: 100px;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  @media only screen and (max-width: 1000px) {
    display: none;

    position: relative;
    top: 0px;
    padding-right: 30px;
  }

  @media screen and (max-width: 430px) {
  }
`
export const IntroText = styled.p`
  color: #706f6f;
  font-family: Barlow;
  font-size: 17.9px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 156.425% */
  padding: 20px;
  a {
    color: #1d9ed9;

    text-decoration-line: underline;
  }
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const TopText = styled.p`
  color: #0d3151;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px; /* 140% */
  margin-bottom: 30px;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const LeftLink = styled.p`
  position: relative;
  left: 30px;
  color: #706f6f;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 35px;
  cursor: pointer;
  .active {
    color: #299cc1;
  }

  @media only screen and (max-width: 1000px) {
    margin-bottom: 25px;
  }

  @media screen and (max-width: 430px) {
  }
`
export const GreyBlock = styled.div`
  scroll-margin-top: 91px;

  background: #f3f5f6;
  padding: 45px 20px;
  margin-top: 45px;
  margin-bottom: 50px;
  h3 {
    margin-top: 25px;
  }
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const BlueLine = styled.div`
  position: absolute;
  width: 3px;
  height: calc(100% - 40px);
  background: #56c3e5;
  top: 55px;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const MiddleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px 0;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
    flex-direction: column;
  }
`
export const GradientText = styled.div`
  font-family: Barlow;
  height: 100%;
  padding: 25px;
  font-size: 95px;
  font-weight: 700;
  background: -webkit-linear-gradient(90deg, #a8ce3a, #1a9dd8);
  background: linear-gradient(90deg, #a8ce3a, #1a9dd8);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const SideText = styled.div`
  color: #0d3151;
  font-family: Barlow;
  font-size: 22.68px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 26.081px */
  width: 254px;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
    text-align: center;
    margin-top: 30px;
  }
`
export const H2 = styled.h2`
  scroll-margin-top: 91px;
  scroll-behavior: smooth;

  color: #0d3151;
  font-family: Barlow;
  font-size: 39.9px;
  font-style: normal;
  font-weight: 800;
  line-height: 49.98px; /* 125.263% */
  margin-bottom: 35px;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const H3 = styled.h3`
  scroll-margin-top: 91px;
  scroll-behavior: smooth;
  color: #0d3151;
  font-family: Barlow;
  font-size: 31.5px;
  font-style: normal;
  font-weight: 800;
  line-height: 49.98px; /* 158.667% */
  margin: 45px 0 25px 0;
  @media only screen and (max-width: 830px) {
    width: 100%;
    text-align: left;
  }

  @media screen and (max-width: 430px) {
  }
`
export const P = styled.p`
  color: #706f6f;
  font-family: Barlow;
  font-size: 17.9px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 156.425% */
  margin: 12px 0 30px 0;
  ol,
  ul {
    position: relative;
    left: 20px;
    list-style: auto;
  }

  a {
    color: #1d9ed9;

    text-decoration-line: underline;
  }
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
    ol,
    ul {
      position: relative;
      left: 0px;
      list-style: auto;
    }
  }
`
export const PUL = styled.div`
  color: #706f6f;
  font-family: Barlow;
  font-size: 17.9px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 156.425% */
  margin: 12px 0 30px 0;
  width: 80%;
  &.greyedUL {
    ol,
    ul {
      position: relative;
      left: 30px;
      list-style: auto;
    }
  }
  ol,
  ul {
    position: relative;
    left: 30px;
    list-style: auto;
  }

  a {
    color: #1d9ed9;

    text-decoration-line: underline;
  }
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
    &.greyedUL {
      ol,
      ul {
        position: relative;
        left: 30px;
        list-style: auto;
      }
    }
    ol,
    ul {
      position: relative;
      left: 10px;
      list-style: auto;
    }
  }
`
export const ImgTitle = styled.p`
  color: #706f6f;
  text-align: right;
  font-family: Barlow;
  font-size: 17.9px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px; /* 156.425% */
  margin-bottom: 30px;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const PMSsustavIMG = styled.div`
  width: 554px;
  height: 436px;
  margin: 0 auto;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  @media only screen and (max-width: 550px) {
    width: 92%;
    height: auto;
  }

  @media screen and (max-width: 430px) {
  }
`
export const FullWidthImg = styled.div`
  width: 100%;
  height: auto;
  margin: 50px auto 0px auto;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const CenteredMiddleImg = styled.div`
  width: 60%;
  height: auto;
  margin: 50px auto 0px auto;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const ButtonCTA = styled.div`
  width: 356px;
  height: 69px;
  flex-shrink: 0;
  border-radius: 500px;
  background: var(--Blue, #1c9dd9);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  font-family: Barlow;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 25.3px */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px auto 15px auto;
  cursor: pointer;
  @media only screen and (max-width: 430px) {
    width: 100%;
    font-size: 18px;
  }

  @media screen and (max-width: 430px) {
  }
`
export const UnderButtonText = styled.p`
  color: #0d3151;
  text-align: center;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 18.4px */
  text-align: center;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
