import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export const WrapHMS = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  box-sizing: border-box;
  max-width: 1860px;
  min-width: 1200px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    min-width: unset;
    width: 100vw;
  }
  @media only screen and (max-width: 425px) {
  }
`

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Header from 'components/Header'
import Footer from 'components/Footer'

import Logo from 'components/Logo'
import Button from 'components/Button'

import NotFoundImg from 'images/not-found-404.png'

import FAQauthority from '../../components/hotel-property-management-system/FAQauthority'
import GetStartedCta from '../../components/GetStartedCTA'
import Banner from '../../components/Hotel-management system en/banner'
import Content from '../../components/Hotel-management system en/content'
import schema from '../../components/Hotel-management system en/schema.json'

const faqData = [
  {
    content:
      'A hotel management system is a set of hotel solutions such as a property management system (PMS), channel manager, web booking engine, mobile app, and integrations. Get free consultations today.',
    title: 'What is a Hotel Management System (HMS)?'
  },
  {
    content:
      'Hotel PMS stands for a property management system used by hotel managers and staff to manage the entire hotel business, from reservations to checkout. Find out more about cloud-based hotel property management system. ',
    title: 'What is a Hotel PMS?'
  },
  {
    content:
      'The main benefits of having integrated PMS, Channel Manager, and Web Booking Engine are the synchronization of all channels, fast implementation and onboarding process, one support team, and cost benefits. Get a personalized demo today. ',
    title: 'What are the benefits of integrated PMS, Channel manager, and Booking Engine?'
  },
  {
    content:
      'Rentlio is a cloud-based hotel management system provider which offers integrated PMS, Channel Manager, Booking Engine, Mobile App, Integrations, and much more. Discover how Rentlio can help you digitally transform your hotel business.',
    title: 'What is Rentlio?'
  }
]
const cta_mobile_apps = {
  title: 'READY TO GET STARTED?',
  description:
    "Create your account and invite your team. You'll save time and earn more. Give us a try; you won't regret it!"
}

const HPMS = ({ location }) => {
  return (
    <Layout locale="en">
      <SEO
        title="#1 Hotel Management System "
        description="Discover what is a hotel management system and what are the key HMS solutions critical to run a successful hotel business. "
        pathname={location.pathname}
        lang="en"
        location={location}
        schemaMarkup={schema}
      />
      <Header mode="dark" locale="en" color={'#FFFFFF'} opacity={0.3} location={location} hasTranslation="false" />
      <WrapHMS>
        <Banner />
        <Content locale="en" />
      </WrapHMS>
      <FAQauthority questions={faqData} title="FAQ" authority />
      <GetStartedCta data={cta_mobile_apps} locale="en" location={location} />

      <Footer locale="en" location={location} />
    </Layout>
  )
}

export default HPMS
