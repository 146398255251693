import React, { useState, useEffect, useRef } from 'react'

import {
  WrapContent,
  MiddleTextContent,
  IntroText,
  GreyBlock,
  H2,
  H3,
  P,
  PUL,
  PMSsustavIMG,
  FullWidthImg,
  CenteredMiddleImg,
  ImgTitle,
  MiddleContent,
  GradientText,
  SideText,
  ButtonCTA,
  UnderButtonText,
  LeftColumn,
  TopText,
  LeftLink,
  BlueLine,
  WrapLeftContent
} from './styles'
import Img from 'gatsby-image'

import PMSsustav from '../../../assets/images/PMSsustav.png'
import HMStree from '../../../assets/images/HMStree.png'
import HMSwbe from '../../../assets/images/HMSwbe.png'
import HMSchannelManager from '../../../assets/images/HMSchannelManager.png'
import integrationsAuthority from '../../../assets/images/integrationsAuthority.png'
import HMSHousekeeping from '../../../assets/images/HMSHousekeeping.png'
import Biling from '../../../assets/images/biling.png'
import Kalendar from '../../../assets/images/Kalendar.png'
import Statistika from '../../../assets/images/Statistika.png'
import MobileApps from '../../../assets/images/MobileApps.png'
import reportingDashboard from '../../../assets/images/reportingDashboard.png'
import Form from '../../AuthorityForm'

const links = [
  {
    id: 1,
    title: 'Key Hotel Management System Functionalities'
  },
  {
    id: 2,
    title: 'Utilize All Property Management System Features and Functionalities'
  },
  {
    id: 3,
    title: 'Hotel Management System Cost, Implementation, Onboarding, and Support'
  }
]

const Content = ({}) => {
  useEffect(() => {
    var links = document.querySelectorAll('.link')
    var sections = document.querySelectorAll('.sections')
    window.onscroll = function () {
      var current = 'home'
      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        if (pageYOffset >= sectionTop - 200) {
          current = section.getAttribute('id')
        }
      })
      links.forEach((item) => {
        item.classList.remove('active')
        if (item.href.slice(-1) === current) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
    }
  }, [])

  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://rentl.io/property-management-system'
  }
  return (
    <WrapContent>
      <LeftColumn>
        <WrapLeftContent>
          <BlueLine />
          <TopText>In-page topics</TopText>
          {links.map((link) => (
            <LeftLink key={link.id}>
              <a className="link" href={`#${link.id}`}>
                {link.title}
              </a>
            </LeftLink>
          ))}
        </WrapLeftContent>
      </LeftColumn>
      <MiddleTextContent>
        <H2>Key Benefits of Using Rentlio Hotel Management System</H2>
        <P>
          Rentlio, as a robust Hotel Management System (HMS), presents a host of advantages that significantly enhance
          the efficiency and effectiveness of hotel operations. One key benefit is the seamless integration between
          Rentlio's Property Management System (PMS), Channel Manager, and Web Booking Engine. This integration ensures
          real-time data synchronization, allowing hoteliers to effortlessly manage reservations, update room
          availability, and adjust pricing strategies across multiple channels from a centralized platform.
        </P>
        <P>
          The integration between Rentlio's PMS and Channel Manager is particularly important. It eliminates the risk of
          overbookings by automatically updating room availability across various online travel agencies (OTAs) and
          other distribution channels. This streamlines the booking process and reduces the manual workload for hotel
          staff, minimizing the chances of errors and ensuring a smoother, error-free reservation experience.
        </P>
        <P>
          Additionally, the integration with the Web Booking Engine gives hotels a direct online presence, enabling
          guests to make reservations directly through the hotel's website. This direct booking channel saves hotels on
          commission fees and provides a personalized and branded experience for guests. The seamless integration
          ensures that room availability, rates, and other essential information are consistently updated, fostering a
          user-friendly and reliable booking process.
        </P>
        <P>
          Moreover, Rentlio's integrated approach extends to reporting and analytics, providing hoteliers comprehensive
          insights into their performance across channels. By consolidating data from the PMS, Channel Manager, and Web
          Booking Engine, hotels can make data-driven decisions, optimize pricing strategies, and tailor their marketing
          efforts based on a holistic understanding of their business.
        </P>
        <P>
          Rentlio's Hotel Management System, with its seamless integration between PMS, Channel Manager, and Web Booking
          Engine, offers a unified and efficient solution for hoteliers. This integration minimizes the risk of errors
          and overbookings and provides valuable insights for strategic decision-making, ensuring that hotels can
          enhance their operational efficiency and deliver an exceptional guest experience.
        </P>
        <GreyBlock id="1" className="sections">
          <H2>Key Hotel Management System Functionalities</H2>
          <H3>Property Management System</H3>
          <P>
            Hotel Property Management System (PMS) is the heart of every hotel business responsible for its operation.
            Currently, in the hotel tech industry, there are two types of PMS software: On-Prem and Cloud-Based PMS.
          </P>
          <P>
            On-prem PMS software was developed over twenty years ago and was designed to manage simple front desk tasks
            such as check-in and check-out. Over time the software evolved, but some old processes, such as long time to
            implement, high maintenance cost, complex customizations, and outdated screens, are not keeping up with
            modern hospitality trends.
          </P>
          <P>
            <a href="https://rentl.io/en/hotel">Cloud-based PMS software</a>, on the other hand, was designed to
            simplify font desk operations, automate tasks and processes, reduce cost, and improve efficiency. Known for
            modern-looking screens, fast implementation, and free and automatic upgrades, Cloud PMS is becoming the
            first option for most hoteliers.
          </P>
          <P>
            <a href="https://rentl.io/en/property-management-system">
              Discover the key features of a modern Cloud-based PMS.
            </a>
          </P>
          <FullWidthImg style={{ width: '60%' }}>
            <img src={HMStree} />
          </FullWidthImg>
          <H3>Channel Manager</H3>
          <P>
            Hotel Channel Manager synchronizes prices and availability across all OTA channels, such as Booking.com,
            Expedia, and other marketplaces. Today, many channel manager providers offer connections to your property
            management system. This adds additional cost to already high OTA fees.
          </P>
          <P>
            Rentlio offers integrated PMS and Channel Manager, which are always synched and connected. Pricing and
            availability updates can be done directly inside the property management system and visible across all
            channels in a matter of seconds. This significantly saves time and reduces margins for error.
          </P>
          <P>
            With Rentlio’s integrated PMS and channel manager, you can not only connect to multiple OTA channels but
            also eliminate overbookings. All your bookings and reservations that arrive from any OTA channel are always
            in synch and automatically visible inside the Rentlio PMS.
          </P>
          <P>
            <a href="https://rentl.io/en/channel-manager">
              Find out more about Rentlio’s integrated PMS and Channel Manager.
            </a>
          </P>
          <FullWidthImg>
            <img src={HMSchannelManager} />
          </FullWidthImg>
          <ImgTitle>Hotel Channel Manager</ImgTitle>
        </GreyBlock>
        <Form locale="en" />
        <GreyBlock>
          <H3>Web Booking Engine</H3>
          <P>
            The web booking engine is basically a widget placed on the hotel website to generate direct bookings and
            reservations. Due to the high OTA fees, many hoteliers seek ways to increase revenue by eliminating
            unnecessary costs.
          </P>
          <P>
            Just like for a channel manager, there are many web booking engine providers in the industry. In addition to
            the web booking engine cost, you need to ensure that the provider is integrated with your preferred PMS,
            without which you won’t be able to receive direct bookings.
          </P>
          <P>
            Rentlio’s integrated web booking engine is connected with PMS and can synchronize all reservations across
            all channels. You can customize the widget to adjust the design of your website and to receive direct
            bookings into your PMS.
          </P>
          <P>
            <a href="https://rentl.io/en/booking-engine">
              Discover more about Rentlio’s integrated PMS and Web Booking Engine.
            </a>
          </P>
          <FullWidthImg>
            <img src={HMSwbe} />
          </FullWidthImg>
          <ImgTitle>Hotel Booking Engine</ImgTitle>
        </GreyBlock>
        <H3>Mobile App</H3>
        <P>
          Mobile Apps are very important in digitally transforming your hotel business because they allow hotel managers
          to always keep up with everything happening inside the business. The mobile app helps managers to make
          reservations, change prices, and manage availability directly inside the mobile app.
        </P>
        <P>
          If you have Rentlio PMS, you can easily download iOS or Android apps to your phone, log in, view current
          bookings, and make any changes you wish to make. Rentlio’s Mobile App has an intuitive design that makes it
          simple and easy to use.{' '}
        </P>
        <P>
          <a href="https://rentl.io/en/mobile-apps">
            Learn more about Rentlio’s Moblie App and how it can help you become more efficient.
          </a>
        </P>
        <FullWidthImg>
          <img src={MobileApps} />
        </FullWidthImg>
        <ImgTitle>Rentlio Mobile App</ImgTitle>
        <H3>Integrations</H3>
        <P>
          The current trend and a buzzword in the hotel tech business are integrations. Integrations are basically apps,
          external software, and solutions that are connected to PMS via APIs. There are over 1,000 integrations in the
          industry, from spa management, upselling, and revenue management to the channel manager and booking engine
          covered above.
        </P>
        <P>
          Every hotelier needs to evaluate their own hotel business and find additional apps and software to help them
          become more efficient. Each integration adds additional cost and reduces revenue, so consider what your PMS
          provider offers as a core. Do they offer an integrated channel manager and web booking engine? If the answer
          is yes, this can significantly reduce the overall expense of your hotel business.
        </P>
        <P>
          <a href="https://rentl.io/en/integrations"> Discover more about Rentlio’s integration partners.</a>
        </P>
        <FullWidthImg style={{ width: '80%' }}>
          <img src={integrationsAuthority} />
        </FullWidthImg>
        <GreyBlock>
          <H2 id="2" className="sections">
            Utilize All Property Management System Features and Functionalities
          </H2>
          <P>
            A property management system is a set of features and solutions responsible for managing the operation part
            of the hotel business. Every hotel is different; therefore, the requirements vary based on the hotel's size
            and complexity setup. Below, you can find the{' '}
            <a href="https://rentl.io/en/hotel-property-management-system">key property management system features </a>
            and their impact on the hotel's overall operations.{' '}
          </P>
          <H3>Interactive calendar</H3>
          <P>
            The key for managing all bookings and keeping all OTA channels always in synch. An interactive calendar is a
            place where you can view and manage all reservations, as well as make pricing and availability updates. The
            benefit of having integrated PMS and channel manager is in synchronizing all the information across all OTAs
            and eliminating overbookings.
          </P>
          <FullWidthImg>
            <img src={Kalendar} />
          </FullWidthImg>
          <ImgTitle>Rentlio Interactive Calendar</ImgTitle>
          <H3>Front Desk</H3>
          <P>
            The front desk is where guests first connect with the hotel. Receptionists need to have a friendly
            personality to make a good first impression, but they also need modern and reliable software. Some of the
            key areas where software plays a significant role are a clear and easy-to-view reservation list, a simple
            check-in process utilizing an ID scanner, a fast check-out with many invoice-splitting options, etc.{' '}
          </P>
          <H3>Billing and Invoicing</H3>
          <P>
            Billing and invoicing are much more than just printing or sending invoices. The software allows you to
            customize and brand invoices, split bills based on customer wishes, exclude additional services from the
            bill, and much more. The hotel manager needs to be creative and provide multiple payment options to the
            guest.{' '}
          </P>
          <FullWidthImg>
            <img src={Biling} />
          </FullWidthImg>
          <H3>Housekeeping</H3>
          <P>
            Rooms should be clean and ready upon guest arrival. The housekeeping app connects housekeepers and front
            desk managers to update room status in real-time. You will have the option to create and manage the cleaning
            schedule, view clean, dirty, and out-of-service rooms, and update reservations based on room status in a
            central housekeeping dashboard.{' '}
          </P>
          <FullWidthImg>
            <img src={HMSHousekeeping} />
          </FullWidthImg>
          <H3>Reporting and Analytics</H3>
          <P>
            View over 50 statistics and performance-driven reports. Rentlio’s reporting module offers a 360-degree
            overview of everything happening inside your hotel business. Whether you are looking for simple occupancy
            data or forecasting and planning for the upcoming year, Rentlio reporting can help you find and export all
            the data you are looking for.{' '}
          </P>
          <FullWidthImg>
            <img src={reportingDashboard} />
          </FullWidthImg>
        </GreyBlock>
        <Form locale="en" />
        <H2 id="3" className="sections">
          Hotel Management System Cost, Implementation, Onboarding, and Support
        </H2>
        <H3>Cost</H3>
        <P>
          The cost of a hotel management system varies based on multiple factors such as the number of units, complexity
          setup, number of integrations, support level, etc.
          <a href="https://rentl.io/en/pricing"> Calculate how much you can save by switching to Rentlio.</a>{' '}
        </P>
        <H3>Implementation</H3>
        <P>
          Rentlio’s average implementation is less than 24 hours. This is significantly faster than legacy system
          providers, which take months to implement. Rentlio implementation is fast and free, without hidden fees and
          maintenance costs. Due to the free and automatic upgrades, you will always work on the latest PMS version.
        </P>{' '}
        <H3>Onboarding</H3>
        <P>
          You will not be alone. Rentlio’s onboarding specialists will guide you through the setup process and show you
          how to use each feature and functionality of our software.
        </P>{' '}
        <H3>Support</H3>
        <P>
          Support is the key to success. At Rentlio, we take great pride in our support team, which is always available
          and happy to help with any questions you might have. Our support experts’ average response time is 3:34
          minutes, with 98.9% of responses being rated with the highest grade.
        </P>
        <H3>Key Takeaways</H3>
        <PUL>
          <ul style={{ listStyleType: 'disc' }}>
            <li>Price varies based on the number of units, complexity setup, integrations, and support level.</li>
            <li>Always look for Cloud-based solutions to reduce implementation time and high maintenance fees.</li>
            <li>Ask how long it takes to implement software</li>
            <li>Look for free and automatic upgrades</li>
            <li>Check if the provider offers personal onboarding</li>
            <li>How often is the support team available?</li>
            <li>Ask for some key support matrix such as average response time and success rate.</li>
          </ul>
        </PUL>
        <H3>Why Rentlio?</H3>
        <P>
          Rentlio is a Cloud-Based PMS, Channel Manager, and Web Booking Engine provider based in Zadar, Croatia.
          Currently, running inside over 1,000 properties worldwide, Rentlio is one of the best hotel tech providers in
          the hospitality industry.
        </P>
        <P>
          We at Rentlio believe that the integrated Channel Manager, Web Booking Engine, and PMS are our biggest
          competitive advantages compared to other PMS providers. With Rentlio, you will have only one tech provider,
          one implementation, one onboarding, and one support team here for you.
        </P>
        <ButtonCTA onClick={(e) => handleClick(e)}>Saznajte više o Rentlio PMS-u</ButtonCTA>
        <UnderButtonText>Počnite koristiti svoj budući PMS, već danas!</UnderButtonText>
      </MiddleTextContent>
    </WrapContent>
  )
}

export default Content
