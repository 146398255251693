import React, { useState, useEffect, useRef } from 'react'

import {
  WrapHMSBanner,
  HMSContent,
  Title,
  Text,
  Button,
  BannerTextBlock,
  BannerImageBlock,
  BannerImage1,
  BannerImage2,
  BannerImage3
} from './styles'
import Img from 'gatsby-image'

import HMSbanner from '../../../assets/images/HMSbanner.png'

const Banner = ({}) => {
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://rentl.io/en/contact'
  }

  return (
    <WrapHMSBanner>
      <HMSContent>
        <BannerTextBlock>
          <Title>What is a Hotel Management System?</Title>
          <Text>
            A hotel management system is a set of hotel management functions such as a property management system (PMS),
            channel manager, web booking engine, mobile app, and integrations mainly used by hoteliers to manage the
            entire hotel business.
          </Text>
          <Button onClick={(e) => handleClick(e)}>Get Free Consultations</Button>
        </BannerTextBlock>
        <BannerImageBlock>
          <img src={HMSbanner} />
        </BannerImageBlock>
      </HMSContent>
    </WrapHMSBanner>
  )
}

export default Banner
